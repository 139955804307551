<template>
  <div id="app">
    <h1 class="header">PixlJs</h1>

    <el-row>
      <el-col :span="15">
        <div class="action-left">
          <el-button-group>
            <el-button
              type="primary"
              @click="on_btn_upload"
              :disabled="btn_disabled()"
            >
              <el-icon><UploadFilled /></el-icon>
              {{ t('message.upload') }}</el-button
            >
          </el-button-group>
          <el-button-group>
            <el-button @click="on_btn_new_folder" :disabled="btn_disabled()"
              ><el-icon><Plus /></el-icon
              >{{ t('message.newFolder') }}</el-button
            >
            <el-button
              type="danger"
              @click="on_btn_remove"
              :disabled="btn_disabled()"
              ><el-icon><Delete /></el-icon>{{ t('message.delete') }}</el-button
            >
          </el-button-group>
          <el-button-group>
            <el-button
              style="width: 150px"
              icon="el-icon-top"
              @click="on_btn_up"
              :disabled="btn_disabled()"
              ><el-icon><Top /></el-icon
              >{{ t('message.parentDirectory') }}</el-button
            >
            <el-button @click="on_btn_refresh" :disabled="!total.connected"
              ><el-icon><Refresh /></el-icon
              >{{ t('message.refresh') }}</el-button
            >
          </el-button-group>
        </div>
      </el-col>
      <el-col :span="9">
        <div class="action-right">
          <el-button
            type="success"
            v-if="total.version"
            icon="el-icon-warning"
            >{{ total.version }}</el-button
          >
          <el-button-group>
            <el-button
              type="info"
              @click="on_btn_enter_dfu"
              :disabled="!total.connected"
              ><el-icon><Cpu /></el-icon>DFU</el-button
            >
            <el-button @click="on_btn_ble_connect"
              ><el-icon><Connection /></el-icon
              >{{ total.connBtnText }}</el-button
            >
          </el-button-group>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="folder-path">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>{{ total.current_dir }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>
    <div>
      <!--  -->
      <el-table
        ref="multipleTable"
        :data="total.tableData"
        tooltip-effect="dark"
        style="width: 100%; height: 400px"
        :default-sort="{ prop: 'name', order: 'ascending' }"
        v-loading="total.table_loading"
        element-loading-text="loading.."
        element-loading-spinner="el-icon-loading"
        cell-class-name="file-cell"
        @selection-change="on_table_selection_change"
        @sort-change="on_table_sort_change"
      >
        <el-table-column type="selection" width="42"> </el-table-column>
        <el-table-column
          prop="name"
          :label="t('message.document')"
          sortable
          @sort-method="sort_table_row_name"
          width="320"
          height="300"
        >
          <!-- slot-scope改成#default 否則不顯示列表 -->

          <template #default="scope">
            <div style="display: flex">
              <div style="width: 18px; height: 25px; line-height: 26px">
                <el-icon>
                  <Folder v-if="scope.row.type === 'DIR'" />
                  <Document v-else-if="scope.row.type === 'REG'" />
                  <MessageBox v-else />
                </el-icon>
              </div>
              <div style="">
                <el-link
                  :underline="false"
                  @click="handle_name_click(scope.$index, scope.row)"
                >
                  {{ scope.row.name }}</el-link
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="size"
          :label="t('message.size')"
          sortable
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="type"
          :label="t('message.type')"
          sortable
          width="80"
        >
        </el-table-column>
        <el-table-column prop="notes" :label="t('message.notes')" sortable>
        </el-table-column>
        <!-- slot-scope改成#default 否則不顯示列表 -->
        <el-table-column label="" fixed="right" width="40">
          <template #default="scope">
            <el-dropdown>
              <span class="el-dropdown-link">
                <el-icon class="el-icon--right">
                  <arrow-down />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="on_row_btn_remove(scope.$index, scope.row)"
                    v-if="scope.row.type != 'DRIVE'"
                    >{{ t('message.delete') }}</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="on_row_btn_rename(scope.$index, scope.row)"
                    v-if="scope.row.type != 'DRIVE'"
                    >{{ t('message.rename') }}</el-dropdown-item
                  >
                  <!-- <el-dropdown-item @click.native="on_row_btn_notes(scope.$index, scope.row)"
                  v-if="scope.row.type != 'DRIVE'">备注..</el-dropdown-item> -->
                  <el-dropdown-item
                    @click.native="on_row_btn_meta(scope.$index, scope.row)"
                    v-if="scope.row.type != 'DRIVE'"
                    >{{ t('message.metainformation') }}</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="on_row_btn_format(scope.$index, scope.row)"
                    v-if="scope.row.type == 'DRIVE'"
                    >{{ t('message.format') }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--  v-model="dialogVisible" -->
    <el-dialog
      :title="t('message.metainformation')"
      v-model="total.meta_diag_visible"
      width="30%"
    >
      <el-form ref="form" :model="meta_form" label-width="80px">
        <el-form-item :label="t('message.notes')">
          <el-input v-model="meta_form.notes"></el-input>
        </el-form-item>
        <el-form-item :label="t('message.attribute')">
          <el-checkbox
            :label="t('message.hide')"
            v-model="meta_form.flags.hide"
          ></el-checkbox>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="total.meta_diag_visible = false">{{
          t('message.cancel')
        }}</el-button>
        <el-button type="primary" @click="on_diag_meta_close">{{
          t('message.submit')
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 上传 -->
    <el-dialog
      :title="t('message.upload')"
      width="30%"
      v-model="total.upload_diag_visible"
      :before-close="on_upload_diag_close"
    >
      <!-- :http-request="on_upload_request" -->
      <div>
        <el-upload
          drag
          action="https://jsonplaceholder.typicode.com/posts/"
          multiple
          :http-request="on_upload_request"
          :on-error="on_upload_error"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            {{ t('message.fileDrag') }}<em>{{ t('message.clickToUpload') }}</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            <ul>
              <li>{{ t('message.fileNotice1') }}</li>
              <li>{{ t('message.fileNotice2') }}</li>
            </ul>
          </div>
        </el-upload>
      </div>

      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <!-- 新建文件 -->
    <el-dialog
      v-model="total.dialogFormVisible"
      :before-close="on_file_diag_close"
      width="30%"
      :title="t('message.documentName')"
    >
      <div style="margin: 0 0 50px 0">
        <el-input v-model="total.formName" autocomplete="off" />
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelFile">{{ t('message.cancel') }}</el-button>
          <el-button type="primary" @click="newFileopen">
            {{ t('message.submit') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 重命名 -->
    <el-dialog
      v-model="total.dialogVisibleRename"
      :title="t('message.rename')"
      width="30%"
    >
      <div style="margin: 0 0 50px 0">
        <el-input
          v-model="total.newName"
          :placeholder="t('message.newName')"
        ></el-input>
      </div>

      <span class="dialog-footer-rename">
        <el-button @click="cancelDialog">{{ t('message.cancel') }}</el-button>
        <el-button type="primary" @click="confirmDialog">{{
          t('message.submit')
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch } from 'vue';
import * as pixl from '../lib/pixl.ble';
import { sharedEventDispatcher } from '../lib/event';
import * as proto from '../lib/pixl.proto';
import { ElNotification, ElMessageBox } from 'element-plus';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const dialogVisible = ref(false);
const upload = ref(false);
const list_form = reactive({
  index: '',
  row: '',
});
const meta_form = reactive({
  notes: '',
  flags: {
    hide: false,
  },
  name: '',
  row: null,
});
const total = reactive({
  tableData: [],
  connBtnType: '',
  connBtnText: t('message.connect'),
  version: '',
  connected: false,
  table_loading: false,
  current_dir: '',
  upload_diag_visible: false,
  table_selection: [],
  meta_diag_visible: false,
  dialogFormVisible: false,
  formName: '',
  successNotification: true,
  failNotification: true,
  dialogVisibleRename: false,
  newName: '',
});

onMounted(() => {
  proto.init();
  sharedEventDispatcher().on('ble_connected', (eventData) => {
    on_ble_connected();
  });
  sharedEventDispatcher().on('ble_disconnected', (eventData) => {
    on_ble_disconnected();
  });
  sharedEventDispatcher().on('ble_connect_error', (eventData) => {
    on_ble_connect_error();
  });
});

const on_btn_ble_connect = () => {
  if (total.connected) {
    pixl.disconnect();
    total.connBtnText = t('message.upload');
  } else {
    total.connBtnText = t('message.connecting');
    console.log('连接中..');
    pixl.disconnect();
    pixl.connect();
  }
};
function on_ble_connected() {
  total.connBtnText = t('message.break');
  total.connBtnType = 'success';
  total.connected = true;

  proto.get_version().then((res) => {
    console.log('get version result', res);
    total.version = t('message.Connected') + ' , ver: ' + res.data.ver;
    console.log('成功');
    ElNotification({
      title: 'Pixl.js',
      message: t('message.connectNotice') + 'Pixl.js!',
      type: 'success',
    });
    // if (res.data.ver.startsWith('2.0.0')) {
    //   $alert(
    //     '您设备的固件版本过低，请更新最新版本固件再使用上传功能',
    //     '升级提示',
    //     {
    //       confirmButtonText: '确定',
    //       callback: (action) => {},
    //     }
    //   );
    // }

    var v = res.data;

    // LA.track('pixl_device_connect', { version: v.ver, mac: v.ble_addr });

    reload_drive();
  });
}
const on_ble_disconnected = () => {
  total.connBtnType = '';
  total.connected = false;
  total.connBtnText = t('message.connect');
  total.version = '';
  total.table_loading = false;
  total.tableData = [];
  total.current_dir = '';

  ElNotification({
    title: 'Pixl.js',
    message: 'Pixl.js' + t('message.breakNotice'),
    type: 'error',
  });
};
const on_ble_connect_error = () => {
  total.connBtnType = '';
  total.connBtnText = t('message.connect');
  total.version = '';
  total.table_loading = false;
  total.tableData = [];
  total.current_dir = '';
  $notify({
    title: 'Pixl.js',
    type: 'error',
    message: 'Pixl.js连接失败!',
    duration: 5000,
  });
};

const on_btn_enter_dfu = () => {
  ElMessageBox.confirm(t('message.isDFU'), t('message.notice'), {
    confirmButtonText: t('message.submit'),
    cancelButtonText: t('message.cancel'),
    type: 'warning',
  }).then(() => {
    proto.enter_dfu().then((data) => {
      ElMessageBox.confirm(t('message.enterDFUSuccess'), t('message.notice'), {
        confirmButtonText: t('message.submit'),
        cancelButtonText: t('message.cancel'),
        type: 'success',
      }).then((_) => {
        document.location.href =
          'https://thegecko.github.io/web-bluetooth-dfu/examples/web.html';
      });
    });
  });
};

const on_btn_up = () => {
  var drive = total.current_dir.substring(0, 2); //E:
  var path = total.current_dir.substring(2);

  if (path == '/') {
    // root
    total.current_dir = '';
    reload_drive();
  } else {
    var idx = path.lastIndexOf('/');
    if (idx == 0) {
      total.current_dir = drive + '/';
    } else {
      total.current_dir = drive + path.substring(0, idx);
    }

    reload_folder();
  }
};

const on_btn_refresh = () => {
  if (total.current_dir == '') {
    reload_drive();
  } else {
    reload_folder();
  }
};
const cancelFile = () => {
  total.dialogFormVisible = false;
};
const newFileopen = () => {
  total.dialogFormVisible = false;

  if (total.formName == '') {
    return;
  }
  total.table_loading = true;

  var path = append_segment(total.current_dir, total.formName);
  proto
    .vfs_create_folder(path)
    .then((res) => {
      total.table_loading = false;
      if (res.status == 0) {
        reload_folder();
      } else {
        ElNotification({
          message: t('message.failedToCreateFolder') + '[' + res.status + ']',
          type: 'error',
        });
      }
    })
    .catch((e) => {
      total.table_loading = false;
      ElNotification({
        type: 'error',
        message: t('message.failedToCreateFolder') + '[' + e.message + ']',
      });
    });
};
const on_btn_new_folder = () => {
  total.dialogFormVisible = true;
};

const on_btn_upload = () => {
  total.upload_diag_visible = true;
};

const on_btn_remove = () => {
  if (total.table_selection.length == 0) {
    return;
  }

  const dir = total.current_dir;
  var proceed_count = 0;
  var total_count = total.table_selection.length;
  total.table_loading = true;

  total.table_selection.forEach((v) => {
    proto
      .vfs_remove(append_segment(dir, v.name))
      .then((_) => {
        delete_table_row_by_name(v.name);
        proceed_count++;

        if (proceed_count == total_count) {
          total.table_loading = false;
        }
      })
      .catch((e) => {
        ElNotification({
          type: 'error',
          delFail: 'Delete failed',
          message: v.name + t(message.delFail) + ': ' + e,
        });

        proceed_count++;
        if (proceed_count == total_count) {
          total.table_loading = false;
        }
      });
  }, total);
};
// 关闭上传
// done: () => void
const on_upload_diag_close = (done) => {
  total.upload_diag_visible = false;
  ElMessageBox.confirm(t('message.close'))
    .then((_) => {
      console.log('确认关闭？关闭将清空上传记录。');
      reload_folder();
      done();
    })
    .catch((_) => {});
};
// 关闭新建文件
const on_file_diag_close = () => {
  total.dialogFormVisible = false;
};

const on_upload_request = (options) => {
  proto.vfs_helper_write_file(
    append_segment(total.current_dir, options.file.name),
    options.file,
    (p) => {
      options.onProgress({ percent: (p.written_bytes / p.total_bytes) * 100 });
    },
    (_) => {
      options.onSuccess();
    },
    (e) => {
      options.onError(e);
    }
  );
};

const on_upload_error = (err, file, filelist) => {
  ElNotification({
    type: 'error',
    message: file.name + t('message.uploadFail') + ': ' + err,
  });
};

const on_row_btn_format = (index, row) => {
  ElMessageBox.confirm(
    t('message.FormatOrNot') + row.name + t('message.delData'),
    {
      confirmButtonText: t('message.cancel'),
      cancelButtonText: t('message.submit'),
      type: 'warning',
    }
  ).then(() => {
    total.table_loading = true;
    var path = row.name.substr(0, 1);
    proto
      .vfs_drive_format(path)
      .then((data) => {
        ElNotification({
          type: 'success',
          message: row.name + t('message.formatFail'),
        });

        total.table_loading = false;
        reload_drive();
      })
      .catch((e) => {
        ElNotification({
          type: 'error',
          message: row.name + t('message.formailSuccess') + ' : ' + err,
        });

        total.table_loading = false;
      });
  });
};

const on_row_btn_remove = (index, row) => {
  ElMessageBox.confirm(
    t('message.isDel') + row.name + '?',
    t('message.notice'),
    {
      confirmButtonText: t('message.submit'),
      cancelButtonText: t('message.cancel'),
      type: 'warning',
    }
  ).then(() => {
    total.table_loading = true;
    var path = append_segment(total.current_dir, row.name);
    proto
      .vfs_remove(path)
      .then((data) => {
        total.table_loading = false;
        if (data.status == 0) {
          ElNotification({
            type: 'success',
            message: t('message.delFileSuccess'),
          });

          reload_folder();
        } else {
          ElNotification({
            type: 'error',
            message:
              row.name + t('message.delFileFail') + ' [' + data.status + ']',
          });
        }
      })
      .catch((e) => {
        ElNotification({
          type: 'error',
          message: row.name + t('message.delFileFail') + '[' + err + ']',
        });

        total.table_loading = false;
      });
  });
};

const on_row_btn_notes = (index, row) => {
  ElMessageBox.confirm(t('message.writeNote'), t('message.notice'), {
    confirmButtonText: t('message.submit'),
    cancelButtonText: t('message.cancel'),
    inputValue: row.notes,
  })
    .then(({ value }) => {
      var meta = {
        notes: value,
      };

      var path = append_segment(total.current_dir, row.name);
      proto
        .vfs_update_meta(path, meta)
        .then((res) => {
          if (res.status == 0) {
            row.notes = value;
          } else {
            ElNotification({
              type: 'error',
              message: t('message.updataNoteFail'),
            });
          }
        })
        .catch((e) => {
          ElNotification({
            type: 'error',
            message: e.message,
          });
        });
    })
    .catch(() => {
      //ignore
    });
};
const confirmDialog = () => {
  total.dialogVisibleRename = false;
  total.table_loading = true;
  var path_old = append_segment(total.current_dir, list_form.row.name);
  var path_new = append_segment(total.current_dir, total.newName);
  // console.log(path_old, path_new);
  proto
    .vfs_rename(path_old, path_new)
    .then((res) => {
      total.table_loading = false;
      // console.log(res.status);
      //页面刷新
      on_btn_refresh();
      if (res.status == 0) {
        list_form.name = total.newName;
      } else {
        ElNotification({
          type: 'error',
          message: t('message.renameFail') + '[' + res.status + ']',
        });
      }
    })
    .catch((e) => {
      total.table_loading = false;
      ElNotification({
        type: 'error',
        message: t('message.renameFail') + '[' + e.message + ']',
      });
    });
};
const cancelDialog = () => {
  total.dialogVisibleRename = false;
};
const on_row_btn_rename = (index, row) => {
  console.log(index, row);
  list_form.index = index;
  list_form.row = row;
  total.newName = list_form.row.name;
  total.dialogVisibleRename = true;
};

const on_row_btn_meta = (index, row) => {
  meta_form.name = row.name;
  meta_form.notes = row.notes;
  meta_form.flags = row.flags;
  meta_form.row = row;
  total.meta_diag_visible = true;
};
const on_diag_meta_close = () => {
  total.meta_diag_visible = false;
  var meta = {
    notes: meta_form.notes,
    flags: meta_form.flags,
  };

  // dialogVisible.value = false;
  var path = append_segment(total.current_dir, meta_form.name);

  // var meta_form = meta_form;
  proto
    .vfs_update_meta(path, meta)
    .then((res) => {
      if (res.status == 0) {
        meta_form.row.notes = meta_form.notes;
        meta_form.row.flags = meta_form.flags;
      } else {
        ElNotification({
          type: 'error',
          message: t('message.updataAttribute'),
        });
      }
    })
    .catch((e) => {
      ElNotification({
        type: 'error',
        message: e.message,
      });
    });
};

const on_table_selection_change = (selected) => {
  total.table_selection = selected;
};

const on_table_sort_change = (column, prop, order) => {
  console.log('sort change: ', column, prop, order);
};

const sort_table_row_name = (a, b) => {
  console.log(a, b); //not working
  return a < b ? 1 : -1;
};

const handle_name_click = (index, row) => {
  if (row.type == 'DRIVE') {
    total.current_dir = row.name.substr(0, 3);
    reload_folder();
  } else if (row.type == 'DIR') {
    if (total.current_dir.charAt(total.current_dir.length - 1) != '/') {
      total.current_dir = total.current_dir + '/';
    }
    total.current_dir = total.current_dir + row.name;
    reload_folder();
  }
};

const reload_drive = () => {
  total.table_loading = true;
  var thiz = total;
  proto.vfs_get_drive_list().then((res) => {
    console.log(res);
    const data = res.data;

    var _table_data = [];
    for (var i in data) {
      var drive = data[i];
      var row = {
        name: drive.label + ':/ [' + drive.name + ']',
        size:
          drive.status == 0
            ? format_size(drive.used_size) + '/' + format_size(drive.total_size)
            : '(磁盘不可用[错误代码:' + drive.status + '])',
        type: 'DRIVE',
        icon: 'Folder',
        notes: '',
      };

      _table_data.push(row);
    }
    total.tableData = _table_data;
    // console.log(total.tableData);
    total.table_loading = false;
  });
};

const reload_folder = () => {
  total.table_loading = true;

  proto.vfs_read_folder(total.current_dir).then((h) => {
    total.table_loading = false;
    console.log(h);

    if (h.status == 0) {
      var _table_data = [];
      for (var i in h.data) {
        var file = h.data[i];

        var row = {
          name: file.name,
          size: format_size(file.size),
          type: file.type == 0 ? 'REG' : 'DIR',
          icon: file.type == 0 ? 'Document' : 'Folder',
          notes: file.meta.notes,
          flags: file.meta.flags,
        };
        console.log(row);

        _table_data.push(row);
      }
      total.tableData = _table_data;
    }
  });
};

const format_size = (size) => {
  if (typeof size == 'number') {
    if (size < 1024) {
      return size + ' B';
    } else if (size < 1024 * 1024) {
      return (size / 1024).toFixed(2) + ' KB';
    } else {
      return (size / 1024 / 1024).toFixed(2) + ' MB';
    }
  } else {
    return size;
  }
};

const btn_disabled = () => {
  // console.log(!total.connected);
  return !total.connected || total.current_dir == '';
};

const append_segment = (dir, seg) => {
  var drive = dir.substring(0, 2); //E:
  var path = dir.substring(2);
  if (path == '/') {
    return dir + seg;
  } else {
    return dir + '/' + seg;
  }
};

const delete_table_row_by_name = (name) => {
  for (var i = 0; i < total.tableData.length; i++) {
    if (total.tableData[i].name == name) {
      total.tableData.splice(i, 1);
      return;
    }
  }
};
</script>

<style>
#app {
  font-family: Helvetica, sans-serif;
  width: 960px;
  margin: 0 auto;
}

.action-left {
  float: left;
}

.action-right {
  float: right;
}

.folder-path {
  margin: 20px auto;
}

.folder-action {
  float: right;
}

.file-cell {
  padding: 4px 0 !important;
}

.header {
  text-align: center;
  margin: 100px auto;
}
.fileText {
  width: 300px;
  height: 50px;

  margin: 0 50px 0 0;
}
.ElInput {
  width: 300px;
  height: 100px;
  margin: 10px 50px 0 0;
}
.dialog-footer-rename {
  width: 200px;
  height: 50px;
  margin: 0 0 100px 0;
  /* background-color: aqua; */
}
</style>
