export default {
    message: {
        upload: 'Upload',
        newFolder: 'new Folder',
        parentDirectory: 'parentDirectory',
        delete: 'delete',
        refresh: 'refresh',
        connect: 'connect',
        connecting: 'connecting',
        Connected: 'Connected',
        break: 'break',
        document: 'document',
        size: 'size',
        type: 'type',
        notes: 'notes',
        format: 'format',
        rename: 'rename',
        metainformation: 'metainformation',
        cancel: 'cancel',
        submit: 'submit',
        attribute: 'attribute',
        hide: 'hide',
        fileDrag: 'Drag files here, or',
        clickToUpload: 'Click to upload',
        fileNotice1: 'The total length of the file path cannot exceed 63 bytes, and any excess will be truncated.',
        fileNotice2: 'The file name cannot exceed 47 bytes, and any excess will be truncated.',
        newName: 'Please enter a new name.',
        documentName: 'Please enter a folder name',
        breakNotice: 'You have been disconnected!',
        connectNotice: 'Successfully connected to',
        failedToCreateFolder: 'Failed to create folder',
        close: 'Confirm closing? Closing will clear the upload record.',
        uploadFail: 'upload Fail',
        FormatOrNot: 'Format or not',
        delData: ' ? \Formatting will delete all data! \Formatting may take about 10 seconds, please be patient and wait. prompt',
        formatFail: 'format failed!',
        formailSuccess: 'format complete',
        delFileSuccess: 'Successfully deleted the file!!',
        delFileFail: 'Delete file failed',
        isDel: 'Delete or not',
        notice: 'notice',
        writeNote: 'Please enter a comment',
        updataNoteFail: 'Failed to update comments',
        renameFail: 'Rename failed',
        isDFU: 'Whether to enter DFU mode?',
        enterDFUSuccess: 'Successfully entered DFU mode. Do you want to open the DFU upgrade page？',
        delFail: 'Delete failed',
    }
}