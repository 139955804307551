import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './utils/lang/i18n'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)

// 全局注册el-icon
for (const [name, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(name, component);
}

app.use(router).use(store).use(ElementPlus).use(i18n).mount('#app')
