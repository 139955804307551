export default {
    message: {
        upload: '上传',
        newFolder: '新建文件夹',
        parentDirectory: '上级目录',
        delete: '删除',
        refresh: '刷新',
        connect: '连接',
        Connected: '已连接',
        connecting: '连接中...',
        break: '断开',
        document: '文件',
        size: '大小',
        type: '类型',
        notes: '备注',
        format: '格式化',
        rename: '重命名',
        metainformation: '元信息',
        cancel: '取消',
        submit: '确定',
        attribute: '属性',
        hide: '隐藏',
        fileDrag: '将文件拖到此处，或',
        clickToUpload: '点击上传',
        fileNotice1: '文件路径总长度不能超过63个字节，超过部分会被截断。',
        fileNotice2: '文件名不能超过47个字节，超过部分会被截断。',
        newName: '请输入新名称',
        documentName: '请输入文件夹名',
        breakNotice: '已经断开连接!',
        connectNotice: '已成功连接到',
        failedToCreateFolder: '创建文件夹失败',
        close: '确认关闭？关闭将清空上传记录。',
        uploadFail: '上传失败',
        FormatOrNot: '是否格式化',
        delData: ' ? \n格式化会删除所有数据！\n格式化可能需要10秒钟左右，请耐心等待。提示',
        formatFail: '格式化失败',
        formailSuccess: '格式化完成',
        delFileSuccess: '删除文件成功!',
        delFileFail: '删除文件失败',
        isDel: '是否删除',
        notice: '提示',
        writeNote: '请输入备注',
        updataNoteFail: '更新备注失败',
        renameFail: '重命名失败',
        updataAttribute: '更新属性失败',
        isDFU: '是否进入DFU模式?',
        enterDFUSuccess: '进入DFU模式成功，是否打开DFU升级页面？',
        delFail: '删除失败',

    }
}